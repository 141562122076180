import { defineStyle, extendTheme } from '@chakra-ui/react';
import { withProse } from '@nikolovlazar/chakra-ui-prose';

const fonts = { mono: "'Menlo', monospace" };

const breakpoints = {
    sm: '40em',
    md: '52em',
    lg: '64em',
    xl: '80em',
};

const theme = extendTheme({
    semanticTokens: {
        colors: {
            dark: {
                default: '#0d1126',
            },
            text: {
                default: '#16161D',
                _dark: '#ade3b8',
            },
            heroGradientStart: {
                default: '#7928CA',
                _dark: '#e3a7f9',
            },
            heroGradientEnd: {
                default: '#FF0080',
                _dark: '#fbec8f',
            },
        },
        radii: {
            button: '12px',
        },
    },
    colors: {
        black: '#16161D',
        table: {
            50: '#070A22',
            100: '#070A22',
            200: '#070A22',
            300: '#070A22',
            400: '#070A22',
            500: '#070A22',
            600: '#070A22',
            700: '#070A22',
            800: '#070A22',
            900: '#070A22',
        },
    },
    fonts,
    fontSizes: {
        xs: "0.75rem",
        sm: "0.875rem",
        md: "1rem",
        lg: "1.125rem",
        xl: "1.25rem",
        "2xl": "1.5rem",
        "3xl": "1.875rem",
        "4xl": "2.25rem",
        "5xl": "3rem",
        "6xl": "3.75rem",
        "7xl": "4.5rem",
        "8xl": "6rem",
        "9xl": "8rem",
      },
    fontWeights: {
        hairline: 100,
        thin: 200,
        light: 300,
        normal: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        extrabold: 800,
        black: 900,
      },
    lineHeights: {
        normal: "normal",
        none: 1,
        shorter: 1.25,
        short: 1.375,
        base: 1.5,
        tall: 1.625,
        taller: "2",
      },
      letterSpacings: {
        tighter: "-0.05em",
        tight: "-0.025em",
        normal: "0",
        wide: "0.025em",
        wider: "0.05em",
        widest: "0.1em",
      },
    breakpoints,
    /* components: { 
        Heading: defineStyle({
            margin: 1,
        })
    }, */
    /* styles: {
        global: {
            h1: {
                fontSize: 'xl',
                m: '0',
            },
            h2: {
                fontSize: 'md',
                m: '0',
            },
            p: {
                fontSize: 'sm',
                lineHeight: '1.4',
                m: '0',
            },
        }
    } */
}, withProse(/* {
    baseStyle: {
        h2: {
          m: "0",
        }
      }
} */));

export default theme;
